.carousel {
	width: 90vw;
  height: 60vh;
	position: relative;
	margin: 0 auto;

	.carousel-inner {
    height: 100%;

		.carousel-item {
      height: 100%;

			img {
        height: 100%;
				object-fit: contain;
			}
		}
	}
  .carousel-control-prev-icon,
    .carousel-control-next-icon {
        filter: invert(100%);
        background-color: black;
    }
}
