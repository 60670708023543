@import "../../styles/variables";
@import "../../styles/mixins";

.CopyrightNotice {
	align-items: center;
	font-family: $secondary-font;
	font-size: 1rem;
}

@include breakpoint(tablet) {
	.CopyrightNotice {
		font-size: 1.2rem;
	}
}

@include breakpoint(desktop){
  .CopyrightNotice {
		font-size: 1.5rem;
    line-height: 1.8em;
	}
}

@include breakpoint(larger-desktop){
  .CopyrightNotice {
		font-size: 2rem;
    line-height: 2em;
	}
}