@import "../../styles/variables";
@import "../../styles/mixins";

.NewsComponent{
  background-color: $quote-background;
}

.news-quote{
  font-family: $secondary-font;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
  border-bottom: solid 1px;
}

.cards{
  padding: 4em;
}

.card-img-top-left{
  width: 100px;
  padding: 1em 0 0 1em;

}