@import "../../styles/variables";
@import "../../styles/mixins";

.FilmComponent {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}
