@import "../../styles/variables";
@import "../../styles/mixins";

.title-card {
	font-family: $primary-font;
	font-size: 1.5rem;
	font-weight: bold;
}

.about-text {
	font-size: 1.2rem;
}

.list-about {
  padding-left: 2em;
	list-style-type: circle;
}

@include breakpoint(desktop) {
	.title-card {
		font-size: 2.2rem;
	}
	.about-text {
		font-size: 1.8rem;
	}
}
