@import "../../styles/variables";
@import "../../styles/mixins";

.navbar {
	background-color: $yellow;
	height: 56px;
}

.title-header {
	height: 80px;
	justify-content: center;
	align-items: center;
	font-family: $primary-font;
	font-size: 2rem;
	background-color: $header-title-background;
}

.nav-link {
	transition: color $transition;
	&:hover {
		color: $hover-color;
	}
}

.zoom-icon {
	padding-right: 3em;
}

@include breakpoint(desktop) {
	.title-header {
		font-size: 2.5rem;
		font-weight: 700;
	}
	.navbar-lab-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.logo-lab {
		height: 40px;
		width: auto;
	}
}

@include breakpoint(large-desktop) {
	.navbar-lab-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20%;
		height: 100%;
	}
	.logo-lab {
		height: 50px;
	}
}

@include breakpoint(larger-desktop) {
	.title-header {
		font-size: 3rem;
	}
	.logo-lab {
		height: 60px;
	}
}
