.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	width: 800px;
	text-align: center;
	font-size: 40px;
	position: relative;
}

.close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 18px;
	cursor: pointer;
}
