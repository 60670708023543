@import "../../styles/variables";

.navbar{
  background-color: $header-background;
}
.logo{
  width: 40px;
  height: 40px;
}
.text{
  font-family: $primary-font;
  font-size: 1rem;
}