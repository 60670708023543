*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	font-size: 1.6rem;
	background-color: $body-background;
}

.increase-font {
  font-size: $increase-font !important;
}